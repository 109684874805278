<template>
  <div class="personDegree" ref="container">
    <div class="tab">
      <div class="tabItem" :class="[{'active':activeTab==1}]" @click="toModle(1)">基础信息</div>
      <div class="tabItem" :class="[{'active':activeTab==2}]" @click="toModle(2)">资料填写</div>
    </div>
    <div class="graduateRegisterMain" v-if="registerInfo&&personInfo">
      <div class="aduit-area per-area" v-if="registerInfo.auditStatus==1||registerInfo.auditStatus==2">
        <div class="module-head">
          <div class="module-head-title">审核信息</div>
          <div class="module-head-cont">
            <van-cell-group class="mycell-group">
              <van-field class="myfield" label="提交时间" :value="timestampReturnDate(registerInfo.auditInfo.submitTime,'timeYMDHM')" readonly />
              <van-field class="myfield" label="审核状态" :value="auditStatus[registerInfo.auditInfo.auditStatus]" readonly />
              <van-field class="myfield" label="审核时间" :value="timestampReturnDate(registerInfo.auditInfo.auditTime,'timeYMDHM')" readonly />
              <van-field class="myfield" label="审核意见" :value="registerInfo.auditInfo.auditOpinion" readonly />
            </van-cell-group>
          </div>
        </div>
      </div>
      <div class="image-area per-area">
        <div class="module-head">
          <div class="module-head-title">毕业照片</div>
          <div class="module-head-cont">
            <img class="head-img" v-if="personInfo.graduationUrl"  :src="personInfo.graduationUrl" alt="">
            <span v-else>暂无</span>
          </div>
        </div>
      </div>
      <div class="info-area per-area">
        <div class="module-head">
          <van-cell-group class="mycell-group">
            <van-field class="myfield" label="姓名" v-model="personInfo.name" readonly />
            <van-field class="myfield" label="曾用名" v-model="personInfo.formerName"  :placeholder="onlyRead?'暂无':'请输入'" :disabled="onlyRead"/>
            <van-field class="myfield" label="性别" v-model="sexData[personInfo.sex]" readonly  :placeholder="'暂无'"/>
            <van-field class="myfield" label="民族" v-model="personInfo.nation" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="政治面貌" v-model="personInfo.politicsStatus" :placeholder="'暂无'" readonly />
            <van-field class="myfield" label="证件号" v-model="personInfo.cardId" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="出生日期" :value="timestampReturnDate(personInfo.dateBirth)" readonly  :placeholder="'暂无'"/>
            <van-field class="myfield" label="手机号码" v-model="personInfo.phone" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学习形式" v-model="personInfo.stuTypeName" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="电子邮箱" v-model="personInfo.email" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="批次" v-model="personInfo.admissionBatch" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="入学时间" :value="timestampReturnDate(personInfo.admissionTime)" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学校" v-model="personInfo.tenantName" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="层次" v-model="personInfo.eduCategoryName" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学制" v-model="personInfo.learningSystem" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="学号" v-model="personInfo.studentNum" readonly :placeholder="'暂无'" />
            <van-field class="myfield" label="专业" v-model="personInfo.majorName" readonly  :placeholder="'暂无'"/>
            <van-field class="myfield" label="通讯地址" v-model="personInfo.address" readonly :placeholder="'暂无'" />
          </van-cell-group>
        </div>
      </div>

      <!-- 自定义设置 -->
      <div class="setting-area per-area" ref="setting" v-for="(item, index) in registerInfo.items" :key="index">
        <div class="module-head">
          <div class="infoTitle">{{item.description}}</div>
          <div class="module-head-cont">
            <template v-if="onlyRead">
              {{ item.value }}
            </template>
            <template v-else>
              <van-field
                class="myfield-auto"
                v-model="item.value"
                rows="4"
                autosize
                type="textarea"
                :maxlength="item.wordMax"
                show-word-limit
              />
              <div class="myfieldTips">限制{{ item.wordMin }}-{{ item.wordMax }}字，已写{{ item.value?item.value.length:0 }}字</div>
            </template>
          </div>
        </div>
      </div>
      <!-- 签名 -->
      <div class="signature-area per-area">
        <div class="module-head">
          <div class="module-head-title" :class="{'module-head-title-no':!resultImg}">
            <span><span class="red">*</span>个人签名</span>
            <van-button v-if="!onlyRead" round type="info" class="signature" @click="handleSignature()">点击前往签名</van-button>
          </div>
          <div class="module-head-cont" v-if="resultImg || onlyRead">
            <img class="signature-img" v-if="!onlyRead"  :src="resultImg" alt="">
            <img class="signature-img" v-else  :src="registerInfo.signNameUrl" alt="">
          </div>
        </div>
      </div>
      <van-button round type="info" class="submitBtn" block @click="onSubmit()">{{onlyRead?'返  回':'确认提交'}}</van-button>
    </div>
    <!-- 签名模块 -->
    <div class="signatureWarp" v-if="isShowSignature">
      <div class="signatureBox">
        <vue-esign ref="esign" v-if="windowWidth>windowHeight" :width="windowWidth" :height="windowHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
        <!-- <vue-esign ref="esign"  :width="windowWidth" :height="windowHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" /> -->
      </div>
      <div class="tips"><span class="red">注：</span>请注意书写工整，需要能看清楚字体笔画</div>
      <div class="signatureBtnBox">
        <van-button round  class="signatureBtn" @click="handleSignature()">返回</van-button>
        <van-button round  class="signatureBtn" @click="handleReset()">重写</van-button>
        <van-button round type="info" class="signatureBtn" @click="handleGenerate()">确认保存</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import uploadAli from '@/api/uploadAli.js'
import way from '../../api/encapsulation'
import { Dialog,Toast } from "vant";
import vueEsign from 'vue-esign'
export default {
  name: 'personDegree',
  components: { vueEsign },
  data() {
    return {
      activeTab:1,
      personInfo: {},
      registerInfo: {},
      onlyRead: false,
      isShowSignature: false,
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2030, 11, 31),
      currentDate: new Date(),
      showPicker: false,
      showEduPicker: false,
      showSexPicker: false,
      columns: ["小学", "初中", "高中", "中专", "大专", "本科"],
      sexData: ["男", "女"],

      auditStatus:{
        0:'待审核',
        1:'审核通过',
        2:'审核不通过',
        3:'未提交',
      },
      // 签名配置
      lineWidth: 6,
      lineColor: '#000000',
      bgColor: '',
      resultImg: '',
      isCrop: false,
      windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight,   //实时屏幕高度
    }
  },
  watch: {
    '$store.state.isGetToken'(val) {
      if(val) {
        this.getPersonInfo()
        this.getGraduateRegister()
      }
    },
    windowHeight (val) {
      let that = this;
      console.log("实时屏幕高度：",val, that.windowHeight);
    },
    windowWidth (val) {
      let that = this;
      console.log("实时屏幕宽度：",val, that.windowWidth);
    }
  },
  mounted() {
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight;  // 高
        that.windowWidth = window.fullWidth; // 宽
        console.log(that.windowHeight,'that.windowHeight');
        console.log(that.windowWidth,'that.windowWidth');
      })()
    };
  },
  created() {
    // this.queryEduListEvent();
    //     this.queryWorkList();
    //     this.queryFamilyList();
        // this.getPersonInfo()
        // this.getGraduateRegister()
  },
  methods: {
    dateReturnTimestamp: way.dateReturnTimestamp,
    timestampReturnDate: way.timestampReturnDate,
    toModle(tab) {
      this.activeTab = tab
      // 让页面滚动条滑动到底部
      this.$nextTick(() => {
        let container = this.$refs.container
        let { scrollHeight, clientHeight} = container
        if (tab== 1) {
          console.log(scrollHeight,clientHeight);
          container.scrollTop = 0
        } else if (tab == 2) {
          let offsetTop = this.$refs.setting[0].offsetTop
          container.scrollTop = offsetTop-50
        }
      })
    },
    // 学生信息
    getPersonInfo () {
      this.$request.getPersonInfo().then(res => {
        if (res.data.code == 0) {
          console.log('personInfo');
          this.personInfo = res.data.data
        }
      })
    },
    // 获取登记表信息
    getGraduateRegister () {
      this.$request.getApplication().then(res => {
        if (res.data.code == 0) {
          console.log('getApplication');
          console.log(res.data.data);
          this.registerInfo = res.data.data
          if (this.registerInfo.auditStatus==0 || this.registerInfo.auditStatus==1) {
            this.onlyRead = true
          }
        }
      })
    },
    handleSignature () {
      let type = this.$store.state.deviceType
      this.isShowSignature = !this.isShowSignature
      if (type === 1) {
        window.webkit.messageHandlers.getData.postMessage('changeScreen()')
      } else if (type === 2) {
        window.Android.changeScreen()
      }
    },
    // 重写签名
    handleReset () {
      this.$refs.esign.reset()
    },
    // 保存签名
    handleGenerate () {
      this.$refs.esign.generate({format: 'image/jpeg', quality: 0.5})
      this.$refs.esign.generate().then(res => {
        this.resultImg = res
        console.log(this.resultImg,'resultImg');
        this.handleReset()
        this.handleSignature()
      }).catch(err => {
        // alert(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    },
    // base64转图片
    getFileFromBase64 (base64URL, filename) {
      let arr = base64URL.split(",")
      console.log(arr,'arr');
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: "image/png" });
    },
    async onSubmit () {
      if (!this.onlyRead) {
        for (let i in this.registerInfo.items) {
          let item = this.registerInfo.items[i]
          if (item.wordMin && item.value && item.value.length < item.wordMin || !item.value&& item.wordMin>0) {
            return Toast(item.description+'字数不符');
          }
        }
        if (!this.resultImg) {
          return Toast('还未签名哦');
        }
        let imgtype = await this.getFileFromBase64(this.resultImg, "img.jpeg")
        let img = await uploadAli.uploadOss(imgtype,'img');
        let obj ={
          formerName:this.personInfo.formerName,
          items:this.registerInfo.items,
          signNameUrl:img.excelUrl
        }
        this.$request.applicationSubmit(obj).then(res => {
          if(res.data.code == 0) {
            console.log('返回上一页');
            let type = this.$store.state.deviceType
            if (type === 1) {
              window.webkit.messageHandlers.getData.postMessage('close()')
            } else if (type === 2) {
              window.Android.Close()
            }
          }
        })
      } else {
        let type = this.$store.state.deviceType
        if (type === 1) {
          window.webkit.messageHandlers.getData.postMessage('close()')
        } else if (type === 2) {
          window.Android.Close()
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .personDegree {
    height: 100%;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    // padding: 24px;
    background-color: #F9F9F9;
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
        opacity: 0
    }
    .tab {
      width: calc(100% - 42px);
      display: inline-flex;
      justify-content: space-between;
      padding: 0 24px;
      background: #FFFFFF;
      position: fixed;
      z-index: 55;
      .tabItem{
        flex: 1;
        text-align: center;
        padding: 14px 0;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 16px;
        &.active {
          color: #1A7EE2;
          border-bottom: 2px solid #1A7EE2;
        }
      }
    }
    .graduateRegisterMain {
      padding: 24px;
      margin-top: 40px;
      .per-area {
        &:not(:first-child) {
          .module-head {
            margin-top: 20px;
          }
        }
        &.signature-area {
          .module-head-title {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
          }
          .module-head-title-no {
            padding-bottom: 0 !important;
            border-bottom: none !important;
          }
        }
        .signature {
          width: 104px;
          height: 30px;
          padding: 0;
        }
        .module-head {
          padding: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          background-color: #fff;
          border-radius: 10px;
          position: relative;
          display: flex;
          flex-flow: column;
          .stretch-btn {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
          }
          .infoTitle {
            padding-left: 10px;
            padding-bottom: 17px;
          }
          .module-head-title {
            padding-bottom: 16px;
            border-bottom: 1px solid #F9F9F9;
            margin-bottom: 10px;
          }
          .module-head-cont {
            .head-img {
              width: 100px;
              height: 123px;
              margin: 10px auto 0 auto;
              display: block;
            }
            .signature-img {
              width: 100%;
              height: 123px;
              margin: 10px auto 0 auto;
              display: block;
            }
            .myfieldTips {
              position: absolute;
              right: 20px;
              bottom: 25px;
              background: #FFF;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .module-cont {
          margin-top: 10px;
          background-color: #fff;
          border-radius: 10px;
          overflow: hidden;
          .module-cont-title {
            padding: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            // border-bottom: 1px solid #ebedf0;
            display: flex;
            justify-content: space-between;
            .deleteEle {
              color: rgb(255, 43, 43);
              margin-right: 10px;
            }
          }
          .item-cont {
            // padding: 0 16px;
            .item-list-cont {
              padding: 18px 0;
              border-top: 1px solid #F9F9F9;
            }
          }
        }
        .add-module-area {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          padding: 18px 0;
          margin-top: 10px;
          text-align: center;
          background-color: #fff;
          border-radius: 10px;
        }
      }
    }
    .signatureWarp {
    position: fixed;
    width: calc(100vw);
    height: calc(100vh);
    z-index: 99;
    top: 0;
    background: #F9F9F9;
    padding: 0;
    overflow: hidden;
    .signatureBox {
      border: 1px solid #CCCCCC;
      height: 65vh;
      background: #FFFFFF;
      border-radius: 15px;
      overflow: hidden;
      margin: 20px 40px 0 40px;
    }
    .tips {
      margin: 5px 40px 10px 40px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3B3B3B;
      line-height: 12px;
    }
    .signatureBtnBox {
      margin: 0 40px 0 40px;
      display: flex;
      justify-content: space-between;
      .signatureBtn {
        width: 30%;
        height: 30px;
      }
    }
}
.submitBtn{
  margin-top: 20px;
}
.red{
  color: #FF0202;
  font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
}
  ::v-deep .mycell-group {
    .myfield:first-child {
      padding-top: 0;
    }
    .myfield:last-child {
      padding-bottom: 0;
    }
  }
  ::v-deep .mycell-group::after {
    border: none;
  }
  ::v-deep .myfield-auto {
    border-radius: 10px;
    border: 1px solid #CCCCCC;
    padding: 10px;
    .van-cell__title {
      display: none;
    }
  }
  ::v-deep .myfield {
    padding-left: 0px;
    padding-right: 0px;
    &::after {
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  }
</style>